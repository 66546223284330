// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import "./src/jquery"
import "./src/jquery.visible"
import "./src/jquery.triggerNativeEvent"
import "./src/jquery-ui.min"
import "./src/bootstrap"
import "./src/admin-walkthrough"
import "./src/all-common"
import "./controllers"
import "./src/admin-support"
import "./src/overlayScrollbars.min"
import "./src/jquery.maskedinput.min"
import "./src/select2.full.min"
import "./src/select2-i18n/include-languages"
import "./src/bootstrap-table.min"
import "./src/bootstrap-table-fixed-columns.min"
import "./src/jquery.table-sorter"
import "./src/jquery.stickytableheaders"
import "./src/jquery.mjs.nestedSortable"
import "./src/advanced-report"
import "./src/jquery.multiselect-revised"
import "./src/jquery.multisortable"
